

































































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { getDeptTreeData } from '@/api/system/OrganizationApi';
import { getUserPageData } from '@/api/system/UserApi';
import { RoleSelectDeptUserModel } from '@/models/system/RoleModel';

@Component({
  name: 'BindScope'
})
export default class BindScope extends Vue {
  // ref
  @Ref() readonly orgTreeRef;
  @Ref() readonly userListRef;

  @Prop({ default: '' }) tenantId!: string;
  // 选择的部门、用户列表
  @Prop({ default: [] }) selectScopeList;

  // tabs 选中name
  leftTabsActiveName: string = 'org';

  // 表单搜索Model
  searchForm: any = { name: '' };

  // 用户列表
  userList: any[] = [];
  selectUserList: any[] = [];

  // 组织机构树列表加载状态
  orgTreeListLoading: boolean = false;
  // 组织机构树列表
  orgTreeList: any[] = [];

  // 选择的部门、用户列表加载状态
  selectScopeListLoading: boolean = false;

  // 分页参数
  pageIndex: number = 1;
  // 每页大小
  pageSize: number = 10;
  // 总条数
  total: number = 0;
  // 当前页
  currentPage: number = 1;

  /**
   * onMounted
   */
  mounted() {
    this.searchForm.name = '';
    this.leftTabsActiveNameWatcher(this.leftTabsActiveName);
  }

  /**
   * 添加选中的部门和用户到右侧列表
   */
  addSelectDeptUserToList() {
    // 当前tabs选中的name
    let tabsActiveName = this.leftTabsActiveName;

    // 判断当前tabs是否是组织机构
    if (tabsActiveName === 'org') {
      // 获取选中的树节点
      let checkedNodes = this.orgTreeRef.getCheckedNodes();
      if (checkedNodes.length > 0) {
        checkedNodes.forEach(item => {
          let row: RoleSelectDeptUserModel = {
            id: item.id,
            type: tabsActiveName,
            name: item.name
          };

          // 取消选中
          this.orgTreeRef.setChecked(item.id, false, false);

          // 禁用节点
          this.disabledDeptTreeNode(this.orgTreeList, item.id, true);

          this.selectScopeList.push(row);
        });
      }
    }

    // 判断当前tabs是否是用户列表
    if (tabsActiveName === 'user') {
      // 获取选中的用户
      let selectUserList = this.selectUserList;
      if (selectUserList.length > 0) {
        selectUserList.forEach(item => {
          let row: RoleSelectDeptUserModel = {
            id: item.id,
            type: tabsActiveName,
            name: item.name
          };

          // 用户列表取消选
          this.userListRef.toggleRowSelection(item, false);

          // 添加到右侧选中的列表中
          this.selectScopeList.push(row);
        });

        // 清空选中的用户列表
        this.selectUserList = [];
      }
    }
  }

  /**
   * 右侧选中移除
   * @param index
   * @param item
   */
  removeSelectDeptUserList(index, item) {
    if (item.type === 'org') {
      this.orgTreeListLoading = true;
      // 组织机构树取消选中 取消禁用
      this.orgTreeRef.setChecked(item.id, true, false);
      this.disabledDeptTreeNode(this.orgTreeList, item.id, false);
      this.orgTreeRef.setChecked(item.id, false, false);
      this.orgTreeListLoading = false;
    }
    // 从右侧选中的数组中移除
    this.selectScopeList.splice(index, 1);
  }

  /**
   * 用户列表多选框是否可选中
   * @param row
   * @param index
   */
  userListSelectable(row, index) {
    // 获取右侧选中的列表
    let selectDeptUserList = this.selectScopeList;
    // 如果选中的列表大于0这进入判断
    if (selectDeptUserList.length > 0) {
      let filter = selectDeptUserList.filter(item => item.id === row.id);
      if (filter.length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  /**
   * 根据右侧选中的数据禁用或清除左侧列表数据
   */
  disableOrCleanListData() {
    let selectDeptUserList = this.selectScopeList;
    selectDeptUserList.forEach(item => {
      // 禁用左侧机构树中的节点
      if (item.type === 'org') {
        this.orgTreeListLoading = true;
        // 选中
        this.orgTreeRef.setChecked(item.id, true, false);
        // 禁用节点
        this.disabledDeptTreeNode(this.orgTreeList, item.id, true);
        // 取消选中
        this.orgTreeRef.setChecked(item.id, false, false);
        this.orgTreeListLoading = false;
      }
    });
  }

  /**
   * 启用/禁用机构树节点
   * @param data
   * @param id
   * @param isDisabled
   */
  disabledDeptTreeNode(data, id, isDisabled) {
    data.forEach(item => {
      if (item.id === id) {
        item.hasDisabled = isDisabled;
      }
      if (item.children && item.children.length !== 0) {
        this.disabledDeptTreeNode(item.children, id, isDisabled);
      }
    });
  }

  /**
   * 用户列表选中事件
   * @param val
   */
  handleUserListSelectionChange(val) {
    this.selectUserList = val;
  }

  /**
   * 搜索按钮点击
   */
  handleSearch() {
    let val = this.leftTabsActiveName;
    if ('org' === val) {
      // 获取组织机构树列表
      this.getOrgTreeList(this.searchForm.name);
    } else if ('user' === val) {
      // 恢复默认分页参数
      this.currentPage = 1;
      this.pageIndex = 1;
      this.pageSize = 10;
      // 获取用户列表
      this.getUserList(this.searchForm.name);
    }
  }

  @Watch('leftTabsActiveName')
  leftTabsActiveNameWatcher(val) {
    if ('org' === val) {
      // 获取组织机构树列表
      this.getOrgTreeList();
    } else if ('user' === val) {
      // 获取用户列表
      this.getUserList();
    }
  }

  @Watch('selectDeptUserList', { deep: true })
  selectDeptUserListWatcher() {
    this.disableOrCleanListData();

    this.$emit('done', this.selectScopeList);
  }

  // 关闭弹窗
  handleCloseDialog() {
    this.pageIndex = 1;
    this.selectScopeList = [];
    this.leftTabsActiveName = 'org';
    this.$emit('success', {});
    this.updateVisible(false);
  }

  /**
   * update visible
   * @param value
   */
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  /**
   * 改变分页大小
   */
  changePageSize(pageSize: number) {
    if (pageSize < 1) {
      this.pageSize = 1;
    } else if (pageSize >= this.total) {
      this.pageSize = this.total;
    } else {
      this.pageSize = pageSize;
    }
    this.getUserList();
  }

  /**
   * 跳转至某页
   */
  changePage(page: number) {
    let totalPage = this.total / this.pageSize;
    let remainder = this.total % this.pageSize;
    if (remainder != 0) {
      totalPage++;
    }

    if (page < 1) {
      this.pageIndex = 1;
    } else if (page > totalPage) {
      this.pageIndex = totalPage;
    } else {
      this.pageIndex = page;
    }
    this.getUserList();
  }

  /**
   * 获取组织机构树列表
   */
  getOrgTreeList(name = '') {
    let params = {
      name: name,
      tenantId: this.tenantId
    };
    this.orgTreeList = [];
    this.orgTreeListLoading = true;
    getDeptTreeData(params)
      .then((res: Record<any, any>) => {
        if (res.code == '1') {
          this.orgTreeList = res.data;
          // 根据选中的数据禁用机构树相关节点
          this.disableOrCleanListData();
        } else {
          this.$message.error(res.message);
        }
      })
      .finally(() => {
        this.orgTreeListLoading = false;
      });
  }

  /**
   * 获取用户列表
   */
  getUserList(name = '') {
    let params = {
      name: name,
      tenantId: this.tenantId,
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    this.userList = [];
    getUserPageData(params).then((res: Record<any, any>) => {
      if (res.code == '1' && res.data) {
        this.userList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.message);
      }
    });
  }
}
