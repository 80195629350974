var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      staticClass: "box-card",
      staticStyle: { width: "100%", height: "600px" },
      attrs: {
        "element-loading-text": "Loading...",
        "element-loading-spinner": "el-icon-loading",
      },
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 11 } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: _vm.$t("lang_search_departments_users"),
                          "prefix-icon": "el-icon-search",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleSearch($event)
                          },
                        },
                        model: {
                          value: _vm.searchForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "name", $$v)
                          },
                          expression: "searchForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c("el-button", {
                        attrs: {
                          type: "primary",
                          size: "small",
                          icon: "el-icon-search",
                        },
                        on: { click: _vm.handleSearch },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.leftTabsActiveName,
                    callback: function ($$v) {
                      _vm.leftTabsActiveName = $$v
                    },
                    expression: "leftTabsActiveName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    {
                      staticStyle: { overflow: "auto" },
                      attrs: {
                        label: _vm.$t("lang_the_organization"),
                        name: "org",
                      },
                    },
                    [
                      _c("el-tree", {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.orgTreeListLoading,
                            expression: "orgTreeListLoading",
                          },
                        ],
                        ref: "orgTreeRef",
                        staticStyle: { width: "100%", height: "450px" },
                        attrs: {
                          data: _vm.orgTreeList,
                          "node-key": "id",
                          "show-checkbox": "",
                          "check-strictly": "",
                          "default-expand-all": "",
                          props: {
                            children: "children",
                            label: "displayName",
                            disabled: "hasDisabled",
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    {
                      staticStyle: { overflow: "auto" },
                      attrs: { label: _vm.$t("lang_user"), name: "user" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "page-content",
                          staticStyle: { width: "100%", height: "450px" },
                        },
                        [
                          _c(
                            "el-table",
                            {
                              ref: "userListRef",
                              staticClass: "page-pagetable",
                              attrs: {
                                "row-key": "id",
                                border: "",
                                data: _vm.userList,
                              },
                              on: {
                                "selection-change":
                                  _vm.handleUserListSelectionChange,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "selection",
                                  selectable: _vm.userListSelectable,
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: _vm.$t("lang_account"),
                                  align: "center",
                                  prop: "name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            {
              staticStyle: { "text-align": "center", "padding-top": "270px" },
              attrs: { span: 2 },
            },
            [
              _c("el-button", {
                attrs: { type: "primary", icon: "el-icon-arrow-right" },
                on: { click: _vm.addSelectDeptUserToList },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 11 } },
            [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("lang_selected_departments_and_personnel"))
                ),
              ]),
              _vm.selectScopeList.length === 0
                ? _c("el-empty", {
                    attrs: {
                      description: _vm.$t(
                        "lang_no_selected_department_or_personnel_is_available"
                      ),
                    },
                  })
                : _vm._e(),
              _vm.selectScopeList.length > 0
                ? _c(
                    "div",
                    [
                      _c("vxe-list", {
                        staticClass: "my-list",
                        attrs: {
                          height: "500",
                          loading: _vm.selectScopeListLoading,
                          data: _vm.selectScopeList,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var items = ref.items
                                return _vm._l(items, function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "my-list-item" },
                                    [
                                      item.type === "user"
                                        ? _c("i", {
                                            staticClass: "el-icon-user",
                                          })
                                        : _vm._e(),
                                      item.type === "org"
                                        ? _c("i", {
                                            staticClass: "el-icon-folder",
                                          })
                                        : _vm._e(),
                                      _vm._v(" " + _vm._s(item.name) + " "),
                                      _c("el-button", {
                                        staticClass: "my-list-item-button",
                                        staticStyle: { float: "right" },
                                        attrs: {
                                          type: "text",
                                          icon: "el-icon-delete",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeSelectDeptUserList(
                                              index,
                                              item
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                })
                              },
                            },
                          ],
                          null,
                          false,
                          695557222
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _vm.leftTabsActiveName == "user"
                ? _c("el-pagination", {
                    staticClass: "page-pager",
                    attrs: {
                      background: "",
                      "current-page": _vm.currentPage,
                      "page-sizes": [10, 20, 30, 40],
                      "page-size": _vm.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                    },
                    on: {
                      "size-change": _vm.changePageSize,
                      "current-change": _vm.changePage,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }